<template>
  <div class="row no-gutters">
    <div class="col-12">
      <div class="row no-gutters">
        <div class="col-10 offset-1 col-lg-12 offset-lg-0">
          <div class="m-card text-center">
            <h1 class="o-heading o-heading--xl">
              {{ $t("leggiReferto.title") }}
            </h1>
            <p>
              {{ $t("leggiReferto.subtitle") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-10 offset-1 pt-5" v-if="pin_expired">
      <b-alert class="text-center" variant="warning" v-model="pin_expired">{{ $t("leggiReferto.errors.TIME_OUT") }}</b-alert>
    </div>

    <div class="col-10 offset-1 col-lg-4 offset-lg-4 pt-5 ">

      <div class="m-card" v-if="!pin_expired">
        <div class="m-card__img">
          <b-img-lazy
            src="../../assets/code_ref.svg"
            alt="operatore"
          ></b-img-lazy>
        </div>
        <div class="m-card__form">


          <p>{{$t("leggiReferto.wait")}}</p>
          <b-form-group v-if="false"
            id="pin"
            :label="this.$t('leggiReferto.pin')"
            label-for="pin-input"
            class="m-input"
          >
            <b-form-input
              id="pin-input"
              v-model="pin"
              type="text"

              :placeholder="this.$t('leggiReferto.pin_placeholder')"
              required
            ></b-form-input>
          </b-form-group>

          <b-alert  variant="danger" id="errore_danger" v-model="isErr" >{{ $t("leggiReferto.errors."+this.errMessage) }}</b-alert>
          <div v-if="false" class="m-card__action mt-2" >
            <b-button @click="apriFile()" class=" mb-2 text-light " variant="secondary" size="md" :disabled="this.pin===''">{{
              $t("leggiReferto.action")
            }}</b-button>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import apiAxios from "@/libs/apiAxios";
import router from "@/router";

export default {
  name: "AppLetturaReferto",
  

  data() {
    return {
      passwordHide: "*********",
      isErr:false,
      errMessage:'',

      data: [],

      otp:null,
      hash:null,
      pin_expired: false,
      pin:'',
    };
  },
  created() {
    this.otp=this.$route.query.id;
    this.hash=this.$route.query.hash;
    this.pin_expired = this.$route.query.pin_state == 'timeout';
    this.getFile(0);

  },  
  computed: {
    service:function (){
      let msp=this.$route.query.mdl;
      if (msp!=null) {
        if (msp === 'true' || msp==="1") {
          return 'mdl';
        }
      }
      return 'referti';
    },
    urlPath()
    {
      switch(this.service)
      {
        case "mdl":
          return "mdl/dottoreReferto";
      }
      return "dottoreReferto";

    },
    token:function() {

      let uri = window.location.href.split('?');
      if (uri.length == 2) {
        let vars = uri[1].split('&');
        let getVars = {};
        let tmp = '';
        vars.forEach(function (v) {
          tmp = v.split('=');
          if (tmp.length == 2)
            getVars[tmp[0]] = tmp[1];
        });

        if ('id' in getVars) {
          return getVars.id;
        }

      }
      return null;
    }
  },

  methods: {
    signalError(bError,tError)
    {
      this.isErr=bError;
      this.errMessage=tError;
    },
    async getFile(pin) {
      console.log(pin);
      let res='';
       const data ={
        hash:this.hash,
         otp:this.token
      };

      this.signalError(false,'');
      const service=this.service;

      await apiAxios.post(`/jit/`+this.urlPath , data)
          .then(response => {
            /* console.log(response); */
            const data= response.data.DottoreRefertoResult;

           if (data.success)
           {
             this.signalError(false,'');
             sessionStorage.setItem('referti',JSON.stringify( {referti:[data.data],total:data.data.length,pageSize:data.data.length,page:0,pages:1}));
             sessionStorage.setItem('singolo',false);
             router.push(service==="mdl"?"/referti?mdl=true":"/referti");
           }
           else {
             this.signalError(true,data.error);           }
          })
          .catch((error) => {
            console.log(error)
            res="";
          });

      this.fileData=res;

    },
    pdfOnNewWindow(data){
      const sliceSize=512;
      const byteCharacters = atob(data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }


      const file = new Blob(
          byteArrays,
          {type: 'application/pdf'});
      //Build a URL from the file
      //const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      //window.open(fileURL);
      //location.href = fileURL;
      this.downloadFile(file, "Referto.pdf");         
    },

    downloadFile(blob, fileName){
      const link = document.createElement('a');
      // create a blobURI pointing to our Blob
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      // some browser needs the anchor to be in the doc
      document.body.append(link);
      link.click();
      link.remove();
      // in case the Blob uses a lot of memory
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    },

    apriFile: function (){
      console.log("Apri");
      this.getFile(this.pin);



      //console.log( getJITRefertoFile64('a','b','c'))
    }
  },
};
</script>
